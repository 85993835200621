.activity-summary .ant-row .ant-col {
    &:nth-child(odd) {
        background: #fff;
    }
    &:nth-child(even) {
        background: #f2f2f2;
    }
}

@media (min-width: 768px) {
    .activity-summary .ant-row .ant-col {
        background: none !important;
    }

    .activity-summary .ant-row {
        &:nth-child(odd) {
            background: #fff;
        }
        &:nth-child(even) {
            background: #f2f2f2;
        }
    }
}
