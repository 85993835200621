.chart__container {
    margin-top: 1rem;
    display: none;
}

// .card__column {
//     span {
//         flex: 1 !important;
//     }
// }

@media screen and (min-width: 768px) {
    .chart__container {
        display: block;
    }
    // .card__column {
    //     span {
    //         flex: 1 !important;
    //     }
    // }
}

// @media (max-width: 768px) {
//     .amount-cfa-column {
//         display: none;
//     }
// }