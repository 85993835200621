.button__text {
    display: none;
}

.button__text,
.custom__button span {
    display: none;
}

.custom__component span {
    display: none !important;
}
@media screen and (min-width: 768px) {
    .button__text,
    .custom__button span {
        display: inline-block;
    }
    .custom__component span {
        display: inline-block !important;
    }
}
