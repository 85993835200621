.app-header .ant-skeleton {
    height: 1.8rem !important;
}

.antd_space {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
}
