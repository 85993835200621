.access-denied .ant-result-subtitle {
    color: #0b547c;
}

.home {
    border-color: #1875a7 !important;

    &:hover {
        border-color: #115f89 !important;
    }
}
.get_authorization {
    background: #1875a7 !important;
    border-color: #1875a7 !important;

    &:hover {
        background-color: #115f89 !important;
        border-color: #115f89 !important;
    }
}
