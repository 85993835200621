.partner__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    align-items: center;
    justify-content: center;

    &_item {
        padding: 0.8rem;
        background: #fff;
        border-radius: 6px;

        img {
            // max-width: 100%;
            width: 80px;
            height: 80px;
        }
    }
}

@media screen and (min-width: 768px) {
    .partner__cards {
        &_item {
            padding: 1rem;

            img {
                width: 160px;
                height: 160px;
            }
        }
    }
}
