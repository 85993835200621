.infoBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    font-size: 0.7rem;
    background: #fff;
    padding: 10px 1rem;
}
.infoBar p {
    margin-bottom: 0;
    font-weight: 400;
    display: flex;
    text-align: center;
}

.infoBar p:nth-child(2),
.infoBar p:nth-child(3) {
    display: none;
    flex: none;
}

.infoBar .infoBar-skeleton:nth-child(1) {
    display: none !important;
}

.info-bar__long-date {
    display: none;
}

.info-bar__short-date {
    display: block;
}

@media screen and (min-width: 678px) {
    .infoBar {
        align-items: center;
        height: 40px;
        font-size: 0.6rem;
        background: #fff;
        padding: 10px 1rem;
    }
    .infoBar p {
        text-align: left;
        font-size: 0.8rem;
        flex: 1 !important;
        display: flex !important;
        align-items: center;
        border-right: 1px solid rgb(173, 172, 172);
        padding-left: 15px;

        &:last-child {
            border-right: none;
            display: flex;
            flex: 1;
        }
    }
    .infoBar p:first-child {
        display: flex;
        flex: 1;
    }
    .infoBar .infoBar-skeleton:nth-child(1) {
        display: flex;
    }
    .infoBar .icon {
        font-size: 1.2rem;
        margin-right: 0.4rem;
        opacity: 0.8;
    }
    .infoBar .icon + span {
        font-weight: bold;
        opacity: 0.8;
    }
    .info-bar__long-date {
        display: block;
    }
    .info-bar__short-date {
        display: none;
    }
}
