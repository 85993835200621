.sidebar_lg {
    display: none;
}

@media screen and (min-width: 800px) {
    .sidebar_lg {
        display: block;
    }
}

.sidebar-push {
    margin-right: 3rem;
    animation: fade 0.3s ease-in-out forwads;
}

.sidebar-push + span {
    visibility: hidden;
}

.sidebar-normal {
    opacity: 1;
    margin-right: 0.7rem;
}

.ant-menu-inline-collapsed .sidebar-normal {
    margin-right: 2.5rem;
    margin-left: -4px !important;
}

.ant-menu-inline-collapsed .ant-menu-item {
    padding-left: 20px !important;
}

.sidebar-push,
.sidebar-normal {
    transition: all 0.3s ease-in-out;
    animation: fade 0.3s ease-in-out forwads;
}

.sidebar__icon {
    font-size: 1.3rem !important;
}

// .sidebar__menu {
//     color: #fff;
// }
// .sidebar_submenu {
//     color: #fff;
// }
@keyframes fade {
    from {
        opacity: 1;
        color: currentColor;
    }
    to {
        opacity: 0;
        color: transparent;
    }
}
