.ant-layout-header {
    height: 48px !important;
}

.app-header-menus {
    width: 200px;
    li.ant-menu-item {
        line-height: 30px !important;
        height: 30px !important;
        border-top: 1px solid #f0f2f5;
        &:first-child {
            border: none;
        }
    }
    a:hover {
        color: #ca9d9d !important;
    }
}

.app-header-menus__dropdown {
    width: 100%;
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 0.32rem 0 0.32rem 12px !important;
        border-top: 1px solid #f0f2f5;
    }
    .collapse_item {
        padding: 0.53rem 0 0.53rem 20px !important;
        display: block;
        width: 100% !important;
        border-top: 1px solid #f0f2f5;
        // &:last-child {
        //   border-bottom: none;
        // }
    }
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 !important;
    }
}

.app-switcher .ant-descriptions-header {
    margin-bottom: 0 !important;
}

.app-switcher .ant-descriptions-header .app-switch-link {
    &:hover {
        background: #ca9d9d !important;
    }
}

.app-switcher {
    .ant-descriptions-view table {
        .ant-descriptions-row .app-switch-link {
            margin-left: 3px !important;
        }
    }
}

.bg-active {
    background: #ca9d9d !important;
}
