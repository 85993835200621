.page__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page__header .navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0px;
    margin-bottom: 0px !important;
}
.page__header .menu {
    display: none;
}
/* logo */
.page__header .logo {
    color: #fff;
    font-size: 18px;
}

.page__header .navbar .navbar__item {
    text-decoration: none;
    list-style: none;
    padding: 0.75rem;
    line-height: normal;
    margin: 0.53rem;
}
.ant-menu.ant-menu-dark {
    background: #12638f !important;
}

.navbar .navbar__item .navItem__link {
    color: #fff;
    font-weight: bold;
    border: 2px solid white;
}

@media screen and (min-width: 768px) {
    .toggle,
    .sidebar-nav {
        display: none;
    }
    .page__header .menu {
        display: block;
    }
}

@media only screen and (max-width: 480px) {
    .navbar {
        display: none;
    }
}

img, svg{
    vertical-align:baseline;
}
