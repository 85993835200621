.page__header,
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header {
    height: 50px !important;
    line-height: 50px !important;
    background: none !important;
    .navbar {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.page__header {
    .logo {
        color: whites !important;
    }
    .navbar__item {
        .navItem__link {
            color: white;
        }
    }
    .navbar {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0px;
        margin-bottom: 0px !important;
    }
}

/* logo */

.page__header,
.header .logo {
    color: #003049 !important;
    font-size: 16px;
    text-transform: capitalize;
    cursor: pointer;
}

.navbar__item {
    text-decoration: none;
    list-style: none;
    line-height: normal;
    margin: 20px;

    .navItem__link {
        padding: 6px 12px;
        border-radius: 3px;
        font-size: 16px;
        font-weight: 400;
        transition: all 1s ease-in-out;
        position: relative;
        text-transform: capitalize;

        &::before {
            content: attr(data-item);
            transition: 0.5s;
            color: #567dc7;
            border-bottom: 1px solid #567dc7;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 0;
            overflow: hidden;
        }
        &:hover {
            background-color: #1573a5;
            color: white;
            padding: 0.8rem;
        }
    }
}

.layout {
    height: 100%;
    position: relative;
    /* background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 68.1%); */
}

.sidebar-layout {
    height: calc(100% - 64px);
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 100;
}

//footer
.footer {
    margin-top: 5rem;
    text-align: center;
    color: #bbcbe9;
    padding-top: 6rem;
}

.cover {
    display: none;
}

@media screen and (min-width: 768px) {
    .toggle,
    .sidebar-layout {
        display: none !important;
    }
    .page__header,
    .header .menu {
        display: flex;
    }
}

//antd components
.ant-drawer {
    .ant-drawer-header {
        background: transparent !important;
        width: 250px;
        // background: #001529;
        // border-bottom: 1px solid #021f3a;
        .ant-drawer-title {
            color: #bbcbe9;
        }
    }
    .ant-drawer-content {
        // background: #001529;
        background: transparent !important;
        width: 250px;
        .ant-drawer-body {
            .ant-menu.ant-menu-dark .ant-menu-item-selected,
            .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
                background: #567dc7 !important;
            }
            padding: 0 !important;
        }
    }
}

#main {
    background: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgb(255, 255, 255) 68.1%
    ) !important;
}

/* bootstrap css */

.navbar-brand {
    font-size: 0.8rem;
}

.hero {
    --title-size: 1.2rem;
    background-image: url('../components/partner-card/logos/back.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 100%;
    &__title {
        font-size: var(--title-size);
        letter-spacing: 10px;
        white-space: nowrap;
        margin-bottom: 3rem;
        color: white;
        &--main {
            animation: moveInLeft 1.5s ease-out;
            span {
                display: inline-block;
                flex: 1 1 minmax(maxcontent, calc(33.33% - 1rem));
            }
        }
        &--sub {
            animation: moveInRight 1.5s 1s ease-out;
            margin-top: 0.4rem;
            span {
                display: inline-block;
                flex: 1;
                letter-spacing: 2px;
            }
            font-size: 0.75rem;
            animation: moveInRight 1.5s ease-out !important;
        }
    }
    &__content {
        font-size: 0.83rem;
        font-weight: 300;
        margin-bottom: 2rem;
    }
}

body {
    min-height: 100vh;
}

.login-btn {
    background-color: #12638f !important;
    border: none;
}

@media only screen and (max-width: 480px) {
    .login-btn {
        width: 100%;
        border: none;
    }
}

@media all and (min-width: 768px) {
    .hero {
        --padding: 5rem;
        --title-size: 10rem;
        padding: var(--padding) 0;
        margin-bottom: 100px !important;
        position: relative;
        &__title {
            font-size: var(--title-size);
            backface-visibility: hidden;
            &--main {
                // display: block;
                font-size: 60px;
                font-weight: 500;
                // letter-spacing: 33px;
                animation: moveInLeft 1.5s ease-out;
            }
            &--sub {
                // display: block;
                font-size: 30px;
                font-weight: 700;
                letter-spacing: 9px;
                animation: moveInRight 1.5s ease-out;
            }
        }
        &__content {
            min-width: 60%;
        }
        &__image {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -2;
            object-fit: cover;
            filter: blur(20px);
        }
    }
}

@media only screen and (max-width: 480px) {
    .hero {
        padding-top: 3rem;
    }
    .menu {
        display: none;
    }
}

@media all and (min-width: 1024px) {
    .hero {
        --padding: 5rem;
        --title-size: 3.5rem;
        &__title {
            &--main,
            &--sub {
                font-weight: 700 !important;
                letter-spacing: 1rem;
            }
        }
        &__content {
            font-size: 1.2rem;
        }
    }
}

.partner__cards {
    .card {
        height: 90px;
    }
    .card .card-body {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            vertical-align: middle;
        }
    }
}

@media screen and (min-width: 768px) {
    .project__title {
        text-align: left !important;
    }
    .partner__cards {
        .card {
            height: 200px;
            .card-body {
                text-align: center;
                // display: block;
            }
            img {
                height: 150px;
                width: 150px;
                object-fit: cover;
            }
        }
    }
}

.project__title {
    color: #0e0d0d;
    font-weight: 500;
    text-align: center;
}

.features,
.pitch {
    padding: 4.3rem 0;
}

@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    80% {
        transform: translateX(10px);
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    80% {
        transform: translateX(-10px);
    }
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

.footer__landing {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #12638f !important;
}
