.sm-responsive-drawer .ant-drawer-wrapper-body {
    width: 250px !important;
    background-color: #fff !important;
}
.sidebar__toggler {
    margin-right: 1rem;
    font-size: 1.5rem;
    & > * {
        fill: #fff;
    }
}

.lang__icon {
    height: 24px;
    max-width: 100%;
}

.app-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1010;
    padding: 0.4rem 1rem !important;
    color: #fff;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
        height: 100%;
        display: flex;
        align-items: center;
    }
    &__icon,
    &__link {
        display: none;
    }
    &__title {
        font-weight: bold;
        color: #fff;
        font-size: 1rem !important;
        /* line-height: 50px; */
    }
    &__link {
        color: #fff !important;
        font-size: 1.1rem;
        &:hover {
            cursor: pointer;
        }
        &:first-child {
            margin-right: 8px;
        }
    }
    &__icon {
        color: #fff !important;
        font-size: 1.1rem !important;
        transition: all 200ms ease-in-out;
        &:hover {
            opacity: 0.8;
        }
    }
    .user__profile div:last-child {
        line-height: none;
        position: relative;
        top: -4px;
    }
}

@media screen and (min-width: 768px) {
    .app-header {
        padding: 0 2em !important;
        .user__profile,
        &__icon,
        &__link {
            display: flex;
        }
        .user__profile div:last-child {
            line-height: none;
            position: static;
        }
    }
    .sidebar__toggler {
        display: none !important;
    }
    .searchDrawer .searchForm {
        display: grid;
        place-items: center;
    }
    .searchForm {
        width: 50%;
        margin: 0 auto;
    }
}

.sidebar__toggler {
    margin-right: 1rem;
    font-size: 1.5rem;
    & > * {
        fill: #fff;
    }
}

.lang__icon {
    height: 24px;
    max-width: 100%;
}

.app-header {
    padding: 0.4rem 1rem !important;
    color: #fff;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
        height: 100%;
        display: flex;
        align-items: center;
    }
    &__icon,
    &__link {
        display: none;
    }
    &__title {
        font-weight: bold;
        color: #fff;
        font-size: 1rem;
        line-height: 50px;
        &:hover {
            color: rgba(255, 255, 255, 0.771) !important;
        }
    }
    &__link {
        color: #fff !important;
        font-size: 1.1rem;
        &:hover {
            cursor: pointer;
        }
        &:first-child {
            margin-right: 8px;
        }
    }
    &__icon {
        color: #fff !important;
        font-size: 1.1rem !important;
        transition: all 200ms ease-in-out;
        &:hover {
            opacity: 0.8;
        }
    }
    .user__profile div:last-child {
        line-height: none;
        position: relative;
        top: -4px;
    }
}

@media screen and (min-width: 768px) {
    .app-header {
        padding: 0 2em !important;
        .user__profile,
        &__icon,
        &__link {
            display: flex;
        }
        .user__profile div:last-child {
            line-height: none;
            position: static;
        }
    }
    .sidebar__toggler {
        display: none !important;
    }
    .searchDrawer .searchForm {
        display: grid;
        place-items: center;
    }
    .searchForm {
        width: 50%;
        margin: 0 auto;
    }
}

//react-sidebar-pro
.pro-inner-list-item.popper-element {
    top: 50px !important;
}
