.flex__dashboard__row {
    p {
        flex: 1;
        align-items: center;
        margin-bottom: 0;
        padding: 6px;

        &.flex__right {
            text-align: right;
        }
    }
    &:nth-child(even) {
        background-color: #f8f8f8;
    }
}
